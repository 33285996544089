@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Header Navigation CSS */
.navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}

.container{
    /* max-width: 1200px; */
    /* width: 100%; */
    margin: auto;
}

.navbar{
    /* box-shadow: 0px 5px 10px 0px #aaa; */
    position: fixed;
    width: 100%;
    /* background: #fff; */
    color: #000;
    opacity: 0.85;
    z-index: 100;
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    height: 90px;
    align-items: center;
  
}

.menu-items{
    order: 2;
    display: flex;
    align-items: center;
}
.logo{
    order: 1;
    /* font-size: 2.3rem; */
}

.menu-items li{
    list-style: none;
    margin-left: 0.3rem;
    font-size: 1.3rem;
}

.navbar a{
    color: #444;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
    padding: 9px 15px;
    font-size: 16px;
}
.navbar .web-service{
  color: white;
}
.navbar a:hover{
    color: white;
    background: #ce454c;
    padding: 9px 15px;
}


@media (max-width: 768px){
    .navbar{
        opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 100px;
        background: #fff;
        height: 100vh;
        max-width: 300px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 40px;
        transition: transform 0.5s ease-in-out;
        box-shadow:  5px 0px 10px 0px #aaa;
        overflow: scroll;
    }

    .navbar .menu-items li{
        margin-bottom: 1.8rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .logo{
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 2.5rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
}

/* Banner CSS */

.bounce-in {
    animation: bounce-in 5s ease infinite;
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
    }
    70% { transform: scale(.9); }
    100% { transform: scale(1); }
  }

  .bounce-out-down {
    animation: bounce-out-down 5s ease infinite;
  }
  @keyframes bounce-out-down {
    0% { transform: translateY(0); }
    20% {
      opacity: 1;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(20px);
    }
  }

  .rotate-in-up-left {
    animation: rotate-in-up-left 3s ease infinite;
  }
  @keyframes rotate-in-up-left {
    0% {
      transform-origin: left bottom;
      transform: rotate(90deg);
      opacity: 1;
    }
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
    }
  }

  /* courses css */

  .courses .tag {
    position: absolute;
    top: -25px;
    right: -4px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    background: #2b4eff;
    color: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    -webkit-box-shadow: 0px 8px 20px 0px rgb(1 23 133 / 30%);
    -moz-box-shadow: 0px 8px 20px 0px rgba(1, 23, 133, 0.3);
    box-shadow: 0px 8px 20px 0px rgb(1 23 133 / 30%);
}

.courses .tag::after {
  border-left-color: #2b4eff;
}
.courses .tag::after {
  position: absolute;
  content: "";
  bottom: -5px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid #2b4eff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
  
.card-img-top:hover {
  transform: scale(1.1);
  
}
.card-img-top {
  transition: transform 0.5s;
}

/* Become CSS */
.intern-text{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.intern{
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('https://img.freepik.com/free-photo/man-handshaking-his-employer-after-being-accepted-his-new-office-job_23-2149034565.jpg');
  background-size: cover;
  overflow: hidden;
  transition: transform 1s;
}
.int, .ins{
  overflow: hidden;
}

.intern:hover, .instructor:hover{
  transform: scale(1.1);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  transition-duration: .4s;
  
  
}
.intern::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.25);
}

.intern:hover::before, .instructor:hover::before{
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  background-color: black;
  opacity: 0.7;
 
}
.intern h1, .intern p{
  position: relative;
  color: #ffffff;

}
.instructor{
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('https://img.freepik.com/free-photo/pretty-young-student-with-big-glasses-near-some-books-smiling-white-background_231208-1850.jpg');
  background-size: cover;
  transition: transform 1s;
}
.instructor::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.25);
}
.instructor h1, .instructor p{
  position: relative;
  color: #ffffff;

}

/* Choose Us CSS */
.chooseUs h1{
  background-image: linear-gradient(to right top, gray, #0e1133);
  background-size: 20% auto;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 font-family: 'Poppins', sans-serif;
 /* font-size: 2vw; */
 animation: gradientAnim 7.5s ease  alternate infinite;
 /* line-height: 60px; */
}
@keyframes gradientAnim {
  to {
    background-position: 100% top;
  }
}

/* Testimonial CSS */
.testimonial-bg{
  background-image: url('../src/Assets/testimonial-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  width: 100%;
  position: relative; 
}

.testimonial__overlay::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,0,0,0.6);
}
.testimonial__overlay p{
  position: relative;
  color: #ffffff;
  z-index: 10;
}

/* what we offer CSS */

.offer figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	/* min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%; */
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.offer figure img {
	position: relative;
	display: block;
	/* min-height: 100%;
	max-width: 100%; */
	opacity: 0.8;
}

.offer figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.offer figure figcaption::before,
.offer figure figcaption::after {
	pointer-events: none;
}

.offer figure figcaption,
.offer figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.offer figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.offer figure h2 {
	word-spacing: -0.15em;
	font-weight: bold;
  color: black !important;
  
}

.offer figure h2 span {
	font-weight: 800;
  background: gray;
  padding: 5px 15px;
  color: white;
}

.offer figure h2,
.offer figure p {
	margin: 0;
}

.offer figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}
figure.effect-sadie figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(top, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
	background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
	content: '';
	opacity: 0;
	-webkit-transform: translate3d(0,50%,0);
	transform: translate3d(0,50%,0);
}

figure.effect-sadie h2 {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	color: #484c61;
	-webkit-transition: -webkit-transform 0.35s, color 0.35s;
	transition: transform 0.35s, color 0.35s;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	opacity: 0;
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}

figure.effect-sadie:hover h2 {
	color: #fff;
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
	transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

figure.effect-sadie:hover figcaption::before ,
figure.effect-sadie:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}


